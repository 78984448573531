<template>
  <div class="mx-auto w-full max-w-sm">
          <div>
            <img class="h-8 w-auto" src="@/master/assets/autralis/logo-rgb-autralis.png" alt="Workflow">
            <h2 class="mt-6 text-xl leading-9 font-bold text-gray-600">
              <translate>Sign in to your account</translate>
            </h2>
            <p v-if="errorMessage" class="mt-8 text-red-600">
              {{ errorMessage }}<br />
            </p>
          </div>
          <div class="mt-8">
            <div class="mt-6">
              <form action="" method="post" id="valid" class="mainForm">
                <div>
                  <label for="id_username" class="block text-sm font-medium leading-5 text-gray-700">
                    <translate>Username or Email address</translate>
                  </label>
                  <div class="mt-1 rounded shadow-sm">
                    <input id="id_username" v-model="username"
                           class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">

                  </div>
                </div>

                <div class="mt-6">
                  <label for="id_password" class="block text-sm font-medium leading-5 text-gray-700">
                    <translate>Password</translate>
                  </label>
                  <div class="mt-1 rounded shadow-sm">
                    <input id="id_password" type="password" v-model="password"
                           class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">

                  </div>
                </div>

                <div class="mt-6 flex items-center justify-between">
                  <div class="flex items-center">
                    <input id="remember_me" type="checkbox"
                           class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out">
                    <label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-900">
                      <translate>Remember me</translate>
                    </label>
                  </div>

                  <div class="text-sm leading-5">
                    <a href="/manager/master/forgotPassword/"
                       class="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                      <translate>Forgot your password?</translate>
                    </a>
                  </div>
                </div>

                <div class="mt-6">
                    <span class="block w-full rounded shadow-sm">
                      <a id="login-button" @click="login"
                              class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out cursor-pointer">
                        <div v-if="loading"><span class="mt-1 mr-2"><span class="fas fa-spinner-third fa-spin"></span></span> <translate>Signing in</translate> ...</div>
                        <div v-else><translate>Sign in</translate></div>
                      </a>
                    </span>
                </div>
              </form>
            </div>
            <div class="mt-8 flex flex-row justify-around">
                <Languages></Languages>
            </div>
          </div>
        </div>
</template>

<script>
import axios from 'axios';
import router from "@/router";
import Languages from "@/master/components/Languages";

export default {
  name: "Login",
  components: {Languages},
  data: function() {
    return {
      loading: false,
      username: null,
      password: null,
      errorMessage: null
    }
  },
  methods: {
    login: function() {
      if(this.loading) return;
      try {
        this.errorMessage = null;
        this.$store.commit('master/logOut');
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        axios.post('/manager/api/v1/obtain-auth-token/',{ username: this.username, password: this.password }, {withCredentials: true}).then((response) => {
              if(response.status!==200) {
                this.loading = false;
                if(response.status>=400&&response.status<500) this.errorMessage = this.$gettext("Please verify username or password");
                if(response.status>=500)  this.errorMessage = this.$gettext("System error, please retry or contact Helpdesk");
                return;
              }
              this.loading = false;
              this.$store.commit('master/setAuthenticated', response.data);
              if(response.data.authenticator) {
                router.push('login-authenticator');
              } else {
                router.push('login-company');
              }

            }
        ).catch(() => {
          this.loading = false;
          this.errorMessage = this.$gettext("Please verify username or password");
        });
      } catch (error) {
        this.loading = false;
        this.errorMessage = this.$gettext("System error, please retry or contact Helpdesk");
      }
    }
  }
}
</script>

<style scoped>

</style>